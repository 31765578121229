<template lang="html">
    <main-layout>
        <template #content>
            <div class="account">
                <div class="heading">
                    <div class="wrapper wrapper-header">
                        <breadcrumbs :steps="breadcrumbsSteps" />
                        <div class="account-user__info">
                            <div class="account-user__avatar">
                                <router-link
                                    :to="{
                                        name: 'profile'
                                    }"
                                >
                                    <avatar
                                        class="ticket-card__avatar-user"
                                        :avatar-fallback="getterPersonalInformation.avatar_fallback"
                                        :avatar-url="getterPersonalInformation.avatar_url"
                                        :firstname="getterPersonalInformation.firstname"
                                        :lastname="getterPersonalInformation.lastname"
                                        :email="getterPersonalInformation.email"
                                    />
                                </router-link>
                            </div>
                            <!-- <div class="account-user__balance">
                                <span class="bigger-text">
                                    {{ getterAccountBalance | money }}
                                </span>
                            </div> -->
                            <div class="account-user">
                                <h1 class="account-user__name">
                                    Hi, {{ firstNameCapitalized }}!
                                </h1>
                                <span
                                    v-if="getterWriterStatuses.trust_status === 'TRUSTED'"
                                    class="account-user__trusted"
                                >
                                    <span>Trusted</span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="account-cards wrapper">
                    <template
                        v-for="(card, index) in $options.cards"
                    >
                        <AccountPageCard
                            :key="index"
                            :card="card"
                        />
                    </template>
                </div>
            </div>
        </template>
    </main-layout>
</template>

<script>
import MainLayout from '@/layouts/MainLayout'
import Avatar from '@/components/common/Avatar'
import Breadcrumbs from '@/components/Breadcrumbs'
import AccountPageCard from '@/views/account/ClientAccountPage/AccountPageCard.vue';
import { cards } from '@/helpers/ClientAccountPage/cards'

import filtersMixin from '@/mixins/filtersMixin.js'

import { mapGetters, createNamespacedHelpers } from 'vuex'

import {
    ACTION_LOGOUT_WRITER,
    ACTION_RESET_ALL_MODULES
} from '@/store/modules/writer/action-types'

import {
    GET_DASHBOARDS
} from '@/store/modules/dashboards/action-types'

const {
    mapActions: mapWriterActions
} = createNamespacedHelpers('writer')

const {
    mapActions: mapDashboardsActions
} = createNamespacedHelpers('dashboards')

export default {
    cards,
    name: 'ClientAccountPage',
    components: {
        Breadcrumbs,
        Avatar,
        MainLayout,
        AccountPageCard
    },
    mixins: [
        filtersMixin
    ],
    data() {
        return {
            cards: this.$options.cards,
            breadcrumbsSteps: [
                {
                    url: '/',
                    title: 'Home'
                },
                {
                    url: '/cp/account',
                    title: 'Dashboard'
                }
            ]
        }
    },
    computed: {
        ...mapGetters('writer', [
            'getterAccountBalance',
            'getterPersonalInformation',
            'getterWriterStatuses',
            'getterWriterStatistics'
        ]),
        unread_order_messages() {
            return this.getterWriterStatistics
                ? this.getterWriterStatistics.unread_order_messages
                : null
        },
        unread_ticket_messages() {
            return this.getterWriterStatistics
                ? this.getterWriterStatistics.unread_ticket_messages
                : null
        },
        firstNameCapitalized() {
            return this.getterPersonalInformation.firstname[0]?.toUpperCase() + this.getterPersonalInformation.firstname.slice(1)
        }
    },
    async created() {
        this[GET_DASHBOARDS]()
        if (this.getterWriterStatuses.accessstatus === 'APPLICANT') {
            this.$router.push({
                name: 'wizard-address'
            })
        }
    },
    methods: {
        ...mapWriterActions([
            ACTION_LOGOUT_WRITER,
            ACTION_RESET_ALL_MODULES
        ]),
        ...mapDashboardsActions([
            GET_DASHBOARDS
        ]),
        async logout() {
            try {
                await this[ACTION_LOGOUT_WRITER]()
                await this[ACTION_RESET_ALL_MODULES]()
                this.$router.push({ name: 'login' })
            } catch (e) {
                console.error(e)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.timezone {
    display: flex;
    align-items: center;
    p {
        margin: 0 !important;
    }
    &-block {
        display: flex;
        &__region, &__gtm {
            display: block;
            line-height: 1;
            font-size: 14px;
        }
        &__region {
            margin-right: 4px;
        }
    }
}
.heading {
    padding: 80px 0 20px;
    background: url('~@/assets/img/bg-writing.jpg') center no-repeat;
    background-size: cover;

    @media (max-width: 992px) {
        h1 {
            font-size: 34px;
        }
    }

    &.profile-heading {
        padding: 80px 0;
        h1 {
            font-size: 56px;
            font-weight: bold;
            color: $white;
        }
    }
}
</style>
