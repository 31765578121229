export const cards = Object.freeze([
    {
        icon: require('@/assets/img/svg/supermarket.svg'),
        title: 'Orders',
        nameRoute: 'orders',
        submenuGetterName: 'getterOrders',
        counter: 'getterOrderCounter'
    },
    {
        icon: require('@/assets/img/svg/chat.svg'),
        title: 'Help Center',
        nameRoute: 'tickets',
        submenuGetterName: 'getterTickets',
        counter: 'getterOpenTickets'
    },
    // {
    //     icon: require('@/assets/img/svg/favorites.svg'),
    //     title: 'Bookmarks',
    //     nameRoute: 'bookmarks',
    //     // submenuGetterName: 'getterBookmarks'
    //     counter: 'getterBookmarksLength'
    // },
    {
        icon: require('@/assets/img/svg/card.svg'),
        title: 'Earnings',
        nameRoute: 'earnings',
        submenuGetterName: 'getterEarnings',
        counter: ''
    },
    {
        icon: require('@/assets/img/svg/dollar.svg'),
        title: 'Payout History',
        nameRoute: 'payouts',
        submenuGetterName: '',
        counter: ''
    },
    // {
    //     icon: require('@/assets/img/svg/account-user.svg'),
    //     title: 'Account settings',
    //     nameRoute: 'profile',
    //     submenuGetterName: '',
    //     counter: ''
    // },
    {
        icon: require('@/assets/img/svg/document.svg'),
        title: 'Documents',
        nameRoute: 'documents',
        submenuGetterName: '',
        counter: '',
        bubbleAttentionGetter: 'getterDocumentsAttention'
    },
    {
        icon: require('@/assets/img/svg/edit-pen-account.svg'),
        title: 'Client Reviews',
        nameRoute: 'clients-feedbacks',
        submenuGetterName: '',
        counter: 'gettersFeedbacksCounter'
    }
])
