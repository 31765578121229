<template>
    <router-link
        :to="{ name: card.nameRoute }"
        class="account-card"
    >
        <div class="account-card__content">
            <div class="account-card__header">
                <h4 class="account-card__title">
                    {{ card.title }}
                    <bubble-attention
                        v-if="isShowBubbleAttention"
                    />
                </h4>
                <span class="account-card__header-counter">
                    <i
                        v-if="card.counter"
                        class="account-card__counter"
                    >
                        {{ getCounts(card) }}
                    </i>
                </span>
            </div>
            <div class="account-card__presets">
                <template v-for="(submenu, index) in getSubItems(card)">
                    <div
                        v-if="submenu.counters?.count"
                        :key="index"
                    >
                        <router-link
                            :to="{
                                name: card.nameRoute,
                                query: {preset: submenu.value}
                            }"
                            class="account-card__get-sub-items"
                        >
                            <div class="account-card__get-sub-items-status">
                                {{ submenu.title }}
                            </div>
                            <span
                                v-if="submenu.counters"
                                class="account-card__get-sub-items-count"
                                :style="{
                                    'background-color': $options.statusBgColor(submenu.value),
                                    'color': $options.statusTextColor(submenu.value)}"
                            >
                                {{ submenu.counters.count | number }}
                            </span>
                        </router-link>
                    </div>
                </template>
            </div>
        </div>
        <img
            class="account-card__icon"
            :src="card.icon"
            :alt="card.title"
        >
    </router-link>
</template>

<script>
import { mapGetters } from 'vuex'
import { statusText, statusBgColor, statusTextColor } from '@/helpers/dashboards/statusHelper'
import filtersMixin from '@/mixins/filtersMixin';
import BubbleAttention from '@/components/BubbleAttention.vue'

export default {
    statusText,
    statusBgColor,
    statusTextColor,
    name: 'AccountPageCard',
    components: {
        BubbleAttention
    },
    mixins: [filtersMixin],
    props: {
        card: {
            type: Object
        }
    },
    computed: {
        ...mapGetters('dashboards', [
            'getterOrders',
            'getterTickets',
            'getterBookmarks',
            'getterOrderCounter',
            'getterOpenTickets',
            'gettersFeedbacksCounter',
            'getterEarnings'
        ]),
        ...mapGetters('bookmarks', [
            'getterBookmarksLength'
        ]),
        ...mapGetters('writer', [
            'getterWriterDataTicketCounter',
            'getterDocumentsAttention'
        ]),
        isShowBubbleAttention() {
            return this[this.card?.bubbleAttentionGetter] ?? false
        }
    },
    methods: {
        getSubItems(item) {
            const { submenuGetterName } = item
            if (submenuGetterName) {
                return this[submenuGetterName]
            }
            return []
        },
        getCounts(item) {
            // const { submenuGetterName } = item
            // if (submenuGetterName) {
            //     return this[submenuGetterName].reduce((acc, { counters }) => {
            //         console.log(counters);
            //         acc += counters.count
            //         return acc
            //     }, 0)
            // }
            const { counter } = item
            if (counter) {
                return this[counter]
            }
            return []
        }
    }
}
</script>

<style scoped lang="scss">
    .account-card__header{
        display: flex;
        justify-content: space-between;
    }
    .account-card__title {
        margin-bottom: 10px;
    }
    .account-card__header-counter{
        position: absolute;
        right: 25px;
    }

    .account-card__get-sub-items {
        display: flex;
        justify-content: space-between;
        font-size: 12px;
        font-weight: 100;
        color: black;
        align-items: center;
        margin-bottom: 5px;
        //line-height: 20px;
    }
    .account-card__icon{
        position: absolute;
        right: 25px;
        bottom: 15px;
    }
    .account-card__get-sub-items-status {
        // width: 40%;
        white-space: nowrap;
        &:hover {
            color: grey;
        }
    }
    .account-card__get-sub-items-count{
        margin-left: 10px;
        padding: 4px;
        font-weight: 500;
    }
</style>
