export const statusText = (str) => {
    const copyStr = str.toUpperCase()
    if (copyStr === 'FREE') {
        return 'Available'
    }
    if (copyStr === 'PENDING') {
        return 'In progress'
    }
    if (copyStr === 'COMPLETE') {
        return 'Completed'
    }
    if (copyStr === 'REVISION') {
        return 'In revision'
    }
    if (copyStr === 'TO_REFUND') {
        return 'To Refund'
    }
    if (copyStr === 'OPEN') {
        return 'Open'
    }
    if (copyStr === 'CLOSED') {
        return 'Closed'
    }
    return str
}

export const statusBgColor = (str) => {
    str = str.toUpperCase()
    if (str === 'OPEN' || str === 'COMPLETE') {
        return 'rgba(89, 199, 77, 0.1)'
    }
    if (str === 'FREE') {
        return 'rgba(17, 97, 224, 0.1)'
    }
    if (str === 'NEW') {
        return 'rgba(17,97,224,.1)'
    }
    if (str === 'PENDING' || str === 'CLOSED') {
        return 'rgba(255, 105, 12, 0.1)'
    }
    if (str === 'REVISION') {
        return 'rgba(136, 86, 242, 0.1)'
    }
    return 'rgba(12, 45, 72, 0.1)'
}

export const statusTextColor = (str) => {
    str = str.toUpperCase()
    if (str === 'OPEN' || str === 'COMPLETE') {
        return '#59C74D'
    }
    if (str === 'FREE') {
        return '#1161e0'
    }
    if (str === 'PENDING' || str === 'CLOSED') {
        return '#ff690c'
    }
    if (str === 'REVISION') {
        return '#8856F2'
    }
    return '#0c2d48'
}
